export class newsletterWidget {
  static init(): void {
    const form = document.getElementById("newsletter-subscribe-form") as HTMLFormElement;
    const resultMessage = document.getElementById("result-message") as HTMLElement;

    if (form && resultMessage) {
      form.addEventListener("submit", (event: Event) => {
        event.preventDefault();
        const formData = new FormData(form);
        const url = form.getAttribute("action") || "";

        fetch(url, {
          method: "POST",
          body: formData
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            form.remove();
            resultMessage.hidden = false;
            resultMessage.textContent = "Du er nu tilmeldt nyhedsbrevet";
          })
          .catch(() => {
            form.remove();
            resultMessage.hidden = false;
            resultMessage.textContent = "Der gik noget galt";
          });
      });
    }

  }
}
