/** Hides or shows Date, time, place and signup fields if Events is chosen */
const onTopicChanged = function (event: JQuery.ChangeEvent) {
  const eventFieldset = $("#events-block");
  if ($("#topic").val() == "Events") {
    eventFieldset.removeAttr('disabled');
    eventFieldset.show();
  } else {
    eventFieldset.attr('disabled', 'disabled');
    eventFieldset.hide();
  }
};

/** Open the file upload UI. */
const openUploadUi = function () {
  $('#image-upload-fld').trigger('click');
};

/** Handle the file upload event. */
const onFilesChanged = function (e: JQuery.ChangeEvent): void {
  const htmlInputElement: HTMLInputElement = e.currentTarget as HTMLInputElement;
  const files: Array<File> = Array.from(htmlInputElement.files || []);

  const filesHtml = files.reduce((result, value, index) => {
    return result +=
      `<li class="list-group-item d-flex justify-content-between align-items-start gap-2" data-index='${index}'>
        ${value.name}
        <a href="#" class="lh-1" title="Slet">
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="ms-auto text-link">
            <path d="M17.1 4.00089H12.6V2.33089C12.5789 1.69071 12.3302 1.08595 11.9084 0.649182C11.4866 0.212416 10.9262 -0.0207168 10.35 0.000890158H7.65C7.07378 -0.0207168 6.51336 0.212416 6.09159 0.649182C5.66982 1.08595 5.42111 1.69071 5.4 2.33089V4.00089H0.9C0.661305 4.00089 0.432387 4.10625 0.263604 4.29378C0.0948211 4.48132 0 4.73567 0 5.00089C0 5.26611 0.0948211 5.52046 0.263604 5.708C0.432387 5.89553 0.661305 6.00089 0.9 6.00089H1.8V17.0009C1.8 17.7965 2.08446 18.5596 2.59081 19.1222C3.09716 19.6848 3.78392 20.0009 4.5 20.0009H13.5C14.2161 20.0009 14.9028 19.6848 15.4092 19.1222C15.9155 18.5596 16.2 17.7965 16.2 17.0009V6.00089H17.1C17.3387 6.00089 17.5676 5.89553 17.7364 5.708C17.9052 5.52046 18 5.26611 18 5.00089C18 4.73567 17.9052 4.48132 17.7364 4.29378C17.5676 4.10625 17.3387 4.00089 17.1 4.00089ZM7.2 2.33089C7.2 2.17089 7.389 2.00089 7.65 2.00089H10.35C10.611 2.00089 10.8 2.17089 10.8 2.33089V4.00089H7.2V2.33089ZM14.4 17.0009C14.4 17.2661 14.3052 17.5205 14.1364 17.708C13.9676 17.8955 13.7387 18.0009 13.5 18.0009H4.5C4.26131 18.0009 4.03239 17.8955 3.8636 17.708C3.69482 17.5205 3.6 17.2661 3.6 17.0009V6.00089H14.4V17.0009Z" fill="currentColor"/>
            <path d="M6.30019 15.001C6.53888 15.001 6.7678 14.8956 6.93658 14.7081C7.10536 14.5205 7.20018 14.2662 7.20018 14.001V10.001C7.20018 9.73576 7.10536 9.48141 6.93658 9.29387C6.7678 9.10633 6.53888 9.00098 6.30019 9.00098C6.06149 9.00098 5.83257 9.10633 5.66379 9.29387C5.49501 9.48141 5.40019 9.73576 5.40019 10.001V14.001C5.40019 14.2662 5.49501 14.5205 5.66379 14.7081C5.83257 14.8956 6.06149 15.001 6.30019 15.001Z" fill="currentColor"/>
            <path d="M11.6999 15.001C11.9386 15.001 12.1675 14.8956 12.3363 14.7081C12.5051 14.5205 12.5999 14.2662 12.5999 14.001V10.001C12.5999 9.73576 12.5051 9.48141 12.3363 9.29387C12.1675 9.10633 11.9386 9.00098 11.6999 9.00098C11.4612 9.00098 11.2323 9.10633 11.0635 9.29387C10.8947 9.48141 10.7999 9.73576 10.7999 10.001V14.001C10.7999 14.2662 10.8947 14.5205 11.0635 14.7081C11.2323 14.8956 11.4612 15.001 11.6999 15.001Z" fill="currentColor"/>
          </svg>
        </a>
      </li>`
  }, '');

  $("#uploaded-files").html(filesHtml);
};

const onRemoveFileClicked = (event: JQuery.ClickEvent) => {
  event.preventDefault();
  const fileElement: JQuery<Element> = $(event.currentTarget.closest("li[data-index]"));
  const fileIndex: number = fileElement.data("index");
  removeFile(fileIndex);
};

const removeFile = (index: number): void => {
  const input = document.getElementById('image-upload-fld') as HTMLInputElement;

  const dataTransfer = new DataTransfer();
  for (let i = 0; i < input.files.length; i++) {
    if (i == index) {
      continue;
    }
    dataTransfer.items.add(input.files.item(i));
  }

  input.files = dataTransfer.files;

  input.dispatchEvent(new Event('change', { bubbles: true }));
};

/** Initializes the Tip page */
const init = function () {
  $('#file-upload-btn').on('click', openUploadUi);
  $('#image-upload-fld').on('change', onFilesChanged);
  $("#topic").on('change', onTopicChanged);
  $('#uploaded-files').on('click', 'li[data-index] a', onRemoveFileClicked);
};

/** Object containing all Tip functionality */
export default {
  init
};
