import * as $ from 'jquery';
import * as Bricklayer from 'bricklayer';
import { Notifications } from './notifications';

export class Search {
  private static page = 0;

  static init() {
    let bl: Bricklayer;

    /*
     * Init search page brick layer.
     */
    document
      .querySelectorAll('.bricklayer-search')
      .forEach(function (section) {
        bl = new Bricklayer(section);
      });

    /*
     * Fetch another page of results.
     */
    $('#btn-fetch-more').on('click', (event: JQuery.ClickEvent) => {
      this.page++;
      const btn = $('#btn-fetch-more');
      // disable the load more button and change text to show user we are loading.
      btn.prop('disabled', true);
      btn.text('Henter...');

      $.ajax({
        type: "POST",
        url: '/api/search',
        data: JSON.stringify({
          query: this.getQueryParam('q'),
          tag: this.getQueryParam('tag'),
          page: this.page
        }),
        contentType: 'application/json',
        cache: false
      }).done((data) => {
        // Add the teasers to the bricklayer element.
        $.each(data.teasers, function (index, value) {
          bl.append($(value)[0]);
        });

        // If more stories avaiable then enable the button the set text; otherwise hide the button.
        if (data.morePages === true) {
          btn.prop('disabled', false);
          btn.text('Hent flere');
        } else {
          btn.hide();
        }
      }).fail(() => {
        // An error occurred, show a message to the user and enable the button.
        Notifications.addNotification('Fejl', 'Det var ikke muligt at henter flere artikler, prøv igen.', `toast-${this.page}`);
        btn.prop('disabled', false);
        btn.text('Hent flere');
      });
    });
  }

  /**
   * Get value of query string parameter.
   * @param key query parameter to get value of.
   */
  static getQueryParam(key: string): string {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&");
    const match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));

    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
}
