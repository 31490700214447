import * as $ from 'jquery';
const Cookies = require('js-cookie');

export class RegionSelector {
  static init(): void {
    const city = Cookies.get('njy-city');

    if (city !== null && city !== undefined) {
      //this.showMessage(`Velkommen til <b>${city.region.replace("oe", "ø")} LigeHer.nu</b>. Her dækker vi også <b>${city.zip} ${city.city}</b>. Du kan altid ændre dit område via menuen.`);
      // Remove the city cookie.
      Cookies.remove('njy-city', { path: '/' });
    }

    // hook up region selector change event.
    $("#RegionSelectorRegionId").bind("keyup", () => {
      let val: string = ($("#RegionSelectorRegionId").val() as string).trim();
      val = val.replace(/\s+/g, '');

      if (val.length === 4) {
        $(".region-selector-loading").show();

        $.ajax({
          type: "GET",
          url: `/api/zipvalidator?zipcode=${val}`,
          contentType: 'application/json',
          cache: false
        }).done((data) => {
          console.log(data);
          if (data.success && data.value !== null && data.value !== undefined) {
            const d = new Date();

            // Set the region cookie.
            //Cookies.set('njy-region-v2', decodeURI(data.value.region), { expires: d.getTime() + (365 * 24 * 60 * 60 * 1000), path: '/' });
            this.setRegionCookie(data.value.region)
            // Set selected city cookie.
            Cookies.set('njy-city', data.value, { expires: d.getTime() + (365 * 24 * 60 * 60 * 1000), path: '/' });
            window.location.href = "/";
          } else {
            // Show error message
            $(".region-selector-loading").hide();
            this.showMessage('Dit postnummer matcher ikke nogen af de nordjyske postnumre. Vores medie dækker kun i Nordjylland. Prøv at vælge dit område via menuen.');
          }
        }).fail(() => {
          console.error("region selector: error validating zip");
          $(".region-selector-loading").hide();
        });
      }
    });
  }

  /**
   * Show a message to the user
   * @param message The message content.
   */
  private static showMessage(message: string): void {
    const messageElement: HTMLDivElement = document.createElement("div");
    messageElement.classList.add("container");
    messageElement.classList.add("top-margin");
    messageElement.classList.add("region-selector-message-wrapper");
    const messageElementInner: HTMLDivElement = document.createElement("div");
    messageElementInner.classList.add("region-selector-message");
    messageElementInner.innerHTML = message;
    const closeBtn: HTMLButtonElement = document.createElement("button");
    closeBtn.classList.add("me-2");
    closeBtn.classList.add("mt-1");
    closeBtn.classList.add("close");
    closeBtn.addEventListener("click", this.removeMessage);
    const closeBtnIcon = document.createElement("span");
    closeBtnIcon.innerHTML = "&times;";
    closeBtn.appendChild(closeBtnIcon);
    messageElement.appendChild(closeBtn);
    messageElement.appendChild(messageElementInner);

    let element: Element = document.getElementsByClassName('region-selector-fullwidth')[0];

    // If there is already showen a message to the user remove it, before adding a new one.
    const oldElement: Element = document.getElementsByClassName('region-selector-message-wrapper')[0];
    if (typeof (oldElement) !== 'undefined' && oldElement !== null && oldElement !== undefined) {
      oldElement.remove();
    }

    // Add the message html to DOM
    if (typeof (element) !== 'undefined' && element !== null && element !== undefined) {
      element.parentNode.insertBefore(messageElement, element.nextSibling);
    } else {
      element = document.querySelector("body > div.container");
      element.parentNode.insertBefore(messageElement, element);
    }

    setTimeout(this.removeMessage, 10000);
  }

  /**
   * Remove the message showen to the user.
   */
  private static removeMessage(): void {
    const element: Element = document.getElementsByClassName('region-selector-message-wrapper')[0];
    if (typeof (element) !== 'undefined' && element !== null && element !== undefined) {
      element.remove();
    }
  }

  /**
   * Set the region cookie.
   * @param region The region to save in the cookie.
   */
  private static setRegionCookie(region: string): void {
    const d = new Date();

    let host = window.location.hostname;
    const splitArr = window.location.host.split('.');

    if (splitArr.length > 2) {
      const subdomain = splitArr[0];

      if (subdomain.length > 0 && subdomain !== 'local') {
        host = host.replace(subdomain + ".", "");
      }

    }

    Cookies.set('njy-region-v2', region, { expires: d.getTime() + (365 * 24 * 60 * 60 * 1000), path: '/', domain: host });

    window.location.href = "/";
  }
}
