export class TabbarActiveLink {
  static init(): void {
    const currentPath = window.location.pathname;
    const tabbar = document.querySelector<HTMLElement>('.tabbar');
    const links = tabbar.querySelectorAll<HTMLAnchorElement>('.nav-link');
    let lastScrollTop = window.scrollY;

    links.forEach(link => {
      const segment = link.pathname;
      if (segment && currentPath.startsWith(segment)) {
        link.classList.add('active');
        link.classList.remove('text-black-50');
      }
    });

   tabbar?.classList.add('show');

    //prepare for future use of show/hide tabbar on scroll
   
    //window.addEventListener('scroll', () => {
    //  const scrollTop = window.scrollY;
    //  if (scrollTop < lastScrollTop) {
    //    // Scrolling down
    //    tabbar?.classList.add('show');
    //  } else {
    //    // Scrolling up
    //    tabbar?.classList.remove('show');
    //  }
    //  lastScrollTop = scrollTop;
    //});
  }
}
