import * as $ from 'jquery';
import * as Cookies from "js-cookie";

export class Menu {
  static init() {
    document.querySelector('[data-toggle-overlay=""]').addEventListener("mousedown", function () {
      if (document.getElementsByTagName("body")[0].classList.contains("menu-open")) {
        $(".hamburger-box").trigger("mousedown");
      }
    });
    // hook up click event for menu.
    $(".hamburger-box").mousedown(() => {
      $(".hamburger").toggleClass("is-active");
      $(".navbar-overlay").toggleClass("hidden");
      $("#city-selector").removeClass('show');
      $("#main-menu").removeClass('hide');
      $("body").toggleClass("menu-open");
    });

    // init region selector.
    this.initRegionSelector();
  }

  /**
   * Region selector initilization.
   */
  static initRegionSelector() {
    // hook up click events for region selector.
    $(".region-modal-trigger").click((e) => {
      e.preventDefault();
      $("#city-selector").toggleClass('show');
      $("#main-menu").toggleClass('hide');
    });
    $(".city-selector-back").click((e) => {
      e.preventDefault();
      $("#city-selector").removeClass('show');
      $("#main-menu").removeClass('hide');
    });
  }
}
